var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "안전작업계획서 샘플 파일" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }