<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="안전작업계획서 샘플 파일" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-upload 
                :attachInfo="attachInfo"
                :editable="editable">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'safetyPlanPop',
  data() {
    return {
      editable: true,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'VENDOR_SAFETY_PLAN_SAMPLE',
        taskKey: 'SAMPLE',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // list setting
      this.getSample();
    },
    getSample() {
      this.$set(this.attachInfo, 'taskKey', 'SAMPLE')
    },
  }
};
</script>
